@import "main";


//  OG CSS

html {
  font-size: 1rem;
}

@include media-breakpoint-up(xs) {
  h1.font-responsive {
    font-size: 2rem !important;
  }
  h1.h2.font-responsive {
    font-size: 1.5rem !important;
  }
}

@include media-breakpoint-up(sm) {
  h1.font-responsive {
    font-size: 2.2rem !important;
  }
  h1.h2.font-responsive {
    font-size: 1.7rem !important;
  }
}

@include media-breakpoint-up(md) {
  h1.font-responsive {
    font-size: 2.4rem !important;
  }
  h1.h2.font-responsive {
    font-size: 1.9rem !important;
  }
}

@include media-breakpoint-up(lg) {
  h1.font-responsive {
    font-size: 2.6rem !important;
  }
  h1.h2.font-responsive {
    font-size: 2.1rem !important;
  }
}

/*Body*/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 1.0);
}
a {
  text-decoration: none;
  &:active {
    text-decoration: underline;
  }
}

.hero-image-b {
  width: 100%;
  min-height: 300px;
  background: no-repeat center;
  background-size: cover;
}

.jumbotron.bg-dark {
  color: #fff;
}
.jumbotron {
  .background {
    position: absolute;
    background-position: bottom right;
    height: 100%;
    overflow: visible;
  }
  .background.background-md {
    background-size: 35em;
  }
  .background.background-sm {
    background-size: 20em;
  }
  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
  .jumbotron-content {
    font-size: 1.4em;
  }
  .input-group-lg {
    >.form-control {
      padding: 1.5rem;
      font-size: 1.5rem;
      line-height: 1.75rem;
      &:not(textarea) {
        height: calc(2.2em + 1rem + 2px);
      }
    }
    .input-group-append {
      .btn {
        padding: 1rem;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }
  }
}
.quote-form {
  .btn-light {
    &:hover {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
    }
  }
  .btn-light.active {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
  }
}
.main-content {
  .card {
    .btn-primary {
      background-color: rgba(230, 241, 255, 1.0);
      border-color: rgba(230, 241, 255, 1.0);
      color: rgba(37, 132, 255, 1.0);
      &:hover {
        background-color: rgba(37, 132, 255, 1.0);
        border-color: rgba(37, 132, 255, 1.0);
        color: rgba(230, 241, 255, 1.0);
      }
    }
  }
  .card.header-card {
    background-color: #e6f1ff;
    border-radius: .5rem;
    border: none;
  }
}
.main-content.content-page {
  a {
    font-weight: bold;
  }
  
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    background-color: #e6f1ff;
    border-radius: .5rem;
    border: none;
    padding: 1rem !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
}
.container.page-body {
  max-width: 1140px !important;
  display: block;
}
/*Footer*/
.footer {
  background-color: #f5f5f5;
  
  ul.list-unstyled li {
    margin-bottom: 0.25rem !important;
  }
}


/* NAV CSS */

.navbar
{
  /*background: #C2BCA9;*/
}

.navbar-btn
{
  padding: 6px 8px;
}

.menu_button.red
{
  background-color:#f00;
}

.menu_button.green, .menu_button.green:hover
{
  background-color:#43ac6a;
}

.navbar-btn.btn-success a
{
  color:#fff !important;
}

.nav .fa
{
  color:#aaa;
}

/* MAIN STYLING CSS */

#site_content p, #site_content li, #site_content h4, #site_content h5
{
  line-height: 1.6em;
}

#site_content p
{
  margin: 0 0 15px;
}

#header_content
{
  margin-top:50px;
}

main.no-jumbotron
{
  margin-top:80px;
}

.jumbotron
{
  opacity: 1;
  background-color: #fff !important;
  background-size: 40px 40px;
  background-image: linear-gradient(
                                    45deg, 
                                    rgba(255, 0, 0, 0.5) 12.5%, 
                                    transparent 12.5%, 
                                    transparent 25%, 
                                    rgba(62,109,179, 0.5) 25%, 
                                    rgba(62,109,179, 0.5) 37.5%, 
                                    transparent 37.5%,
                                    transparent 50%,
                                    rgba(255, 0, 0, 0.5) 50%,
                                    rgba(255, 0, 0, 0.5) 62.5%,
                                    transparent 62.5%,
                                    transparent 75%,
                                    rgba(62,109,179, 0.5) 75%, 
                                    rgba(62,109,179, 0.5) 87.5%,
                                    transparent 87.5%,
                                    transparent
                                    );
  padding:10px;
  margin-top:55px !important;
}

.jumbotron .fill
{
  margin:0;
  padding: 30px 0;
  /*background-color: #eee;*/
  background: #5E85BF;
  /*background: #E8DEBC;*/
  width:100%;
  height:100%;
  color:#fff;
}

/*#jumbotron_content
{
  margin:auto 0;
}*/

.jumbotron h1
{
  font-size:2.2em;
  text-shadow:1px 1px 0px rgba(0,0,0,0.5);
}

.jumbotron li
{
  // font-size: 1.2em
}

.jumbotron .button_container
{
  margin:20px 0 30px 0;
}

.jumbotron a {
  color: #fff !important;
  text-decoration: underline;
  /*text-shadow: 1px 1px 0px rgba(0,0,0,0.5);*/
}

.top_spacer
{
  height:100px;
}

#sidebar_content
{
  /*padding: 30px;*/
  margin-bottom: 30px;
  color: inherit;
  /*background-color: #eee;*/
}

#footer_content
{
  border-top: 1px solid rgba(0,0,0,0.05);
  margin-top:50px;
  padding:20px 0;
  background-color: #e7e7e7;
}

footer p
{
  color:#999;
}

footer p a
{
  color:#555;
}

/* Video Embed */
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus 
{
  background-color: rgba(66,139,202,0.1);
  color:#333;
}

a.anchor {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
    // padding-top: 40px; 
    // margin-top: -40px;
}
